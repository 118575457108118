
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import DTCls from "@/utils/datetime";
import Pagination from "@/comp/Pagination/index.vue";
import { getReportList, reportReply, userFreeze } from "@/api/request/behavior";
import { Message } from "element-ui";

//组件
@Component({
  name: "Report",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private listLoading: boolean = true;

  //修改冻结
  private id: number = 0;
  private hour: string = "";
  private freezeDialog: boolean = false;

  //请求数据
  private listQuery: any = {
    //页码数据
    row: 20,
    page: 1,

    //临时数据
    time: [],

    //请求数据
    type: 0,
    nickname: "",
    end_time: "",
    start_time: "",
    report_type: 0,
    userid: undefined,
  };

  //时间参数
  get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      },
    };
  }

  //类型
  private typeoptions: any[] = [
    { value: 0, label: "全部状态" },
    { value: 3, label: "处理" },
    { value: 5, label: "未处理" },
  ];

  //举报类型
  private typeList: any[] = [
    { type: 0, name: "全部类型" },
    { type: 1, name: "欺诈" },
    { type: 2, name: "涉黄" },
    { type: 3, name: "诽谤" },
    { type: 4, name: "谩骂" },
    { type: 5, name: "骚扰" },
    { type: 6, name: "广告" },
    { type: 7, name: "其它" },
  ];

  //处理重置
  private handleReset(): void {
    //数据赋值
    this.listQuery.page = 1;
    this.listQuery.type = 0;
    this.listQuery.nickname = "";
    this.listQuery.userid = undefined;

    //初始化时间
    this.initTime();

    //获取数据
    this.getList();
  }

  //创建
  created() {
    //初始化时间
    this.initTime();

    //获取数据
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.start_time = `${this.listQuery.time[0]} 00:00:00`;
      this.listQuery.end_time = `${this.listQuery.time[1]} 23:59:59`;
    } else {
      this.listQuery.start_time = "";
      this.listQuery.end_time = "";
    }
    if (this.listQuery.userid) this.listQuery.userid = Number(this.listQuery.userid);

    //获取数据
    const { data } = await getReportList(this.listQuery);

    //数据赋值
    data.list.forEach((item: any, index: any) => {
      data.list[index].pics = item.pics.split(",");
    });
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //初始化时间
  private initTime(): void {
    //数据赋值
    this.listQuery.end_time = `${DTCls.getCurDateTimeYMD()}`;
    this.listQuery.start_time = `${DTCls.getCurDataTimeYMD30()}`;
    this.listQuery.time = [`${this.listQuery.start_time}`, `${this.listQuery.end_time}`];
    this.listQuery.start_time += "  00:00:00";
    this.listQuery.end_time += "  23:59:59";
  }

  //处理举报
  private handleApply(id: any, type: any): void {
    //显示提示
    this.$confirm(`${type === 3 ? "是否对改举报进行处理" : "确认不处理该举报信息"}？`, "警告", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        //请求数据
        const res = await reportReply({ id: id, audit_status: type });

        //操作成功
        if (res.status === 0) {
          //显示提示
          this.$message({ type: "success", message: "操作成功!" });

          //获取数据
          this.getList();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //冻结确认
  private async freezeConfirm() {
    //判断返回
    if (Number(this.hour) == 0) return Message({ message: "请输入时间", type: "error", duration: 5 * 1000 });

    //请求数据
    const res = await userFreeze({ userids: [this.id], status: 7, hour: Number(this.hour) });

    //冻结成功
    if (res.status === 0) {
      //显示提示
      this.$message.success(`冻结成功`);

      //获取数据
      this.getList();
    }

    //隐藏界面
    this.freezeDialog = false;
  }

  //处理冻结
  private handleFreeze(id: any, type: any): void {
    if (type == 7) {
      //数据赋值
      this.id = id;
      this.hour = "24";

      //显示界面
      this.freezeDialog = true;
    } else {
      //显示提示
      this.$confirm(`${type === 1 ? "您确定解冻该用户？" : "您确定冻结该用户？"}`, "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          //数据请求
          const res = await userFreeze({ userids: [id], status: type });

          //操作成功
          if (res.status === 0) {
            //显示提示
            this.$message({ type: "success", message: "操作成功!" });

            //获取数据
            this.getList();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  //处理查询
  private handleFilter() {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }

  //举报类型名称
  private getTypeName(type: number): string {
    for (var i: number = 0; i < this.typeList.length; i++) {
      if (type == this.typeList[i].type) {
        return this.typeList[i].name;
      }
    }
    return "";
  }
}
